import MicroModal from '../vendor/micromodal.js';

export default () => {
  const svgObject = document.querySelector(`.map__image`).contentDocument;
  const svg = svgObject.querySelector(`svg`);
  const groups = svg.querySelectorAll(`.house-group`);

  groups.forEach((group) => {
    const houseHover = group.querySelector(`.house-hover`);
    const houseStatic = group.querySelector(`.house-static`);

    group.style.cursor = `pointer`;
    houseHover.style.visibility = `hidden`;

    group.addEventListener(`mouseover`, () => {
      houseHover.style.visibility = `visible`;
      houseStatic.style.visibility = `hidden`;
    });

    group.addEventListener(`mouseleave`, () => {
      houseStatic.style.visibility = `visible`;
      houseHover.style.visibility = `hidden`;
    });

    group.addEventListener(`click`, () => {
      MicroModal.show(group.id, {
        disableScroll: true,
        disableFocus: false,
      });
    });
  });

  document.querySelector(`.map`).style.opacity = 1;
};
